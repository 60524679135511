import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Folder } from 'app/api/models/folder';
import { BaseComponent } from 'app/base.component';
import AiConfigService from 'app/modules/corpus/corpus-aiconfig-service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import ModalComponent from '../../../../../shared/components/modal/modal.component';

@Component({
    selector: 'aiconfig-folders',
    templateUrl: './aiconfig-folders.component.html',
    styleUrl: './aiconfig-folders.component.scss',
})
export default class AiconfigFoldersComponent extends BaseComponent implements OnInit {
    folders : Array<Folder> = [];

    @Input()
        filter = '';

    @Output()
        foldersChange = new EventEmitter();

    @Output()
        switchToAddMode: EventEmitter<Folder> = new EventEmitter<Folder>();

    @Output()
        folderDeleted: EventEmitter<Folder> = new EventEmitter<Folder>();

    selection: boolean = false;

    editedFolder = undefined;

    constructor(private modalService: NgbModal, private translateService: TranslateService, private aiconfigService: AiConfigService) {
        super();
    }

    get selectOnlyOne() {
        return this.aiconfigService.selectOnlyOne;
    }

    ngOnInit(): void {
        this.subs.sink = this.aiconfigService.folders.subscribe((folders) => {
            if (folders !== null) {
                this.folders = folders;
            }
        });
        this.subs.sink = this.aiconfigService.addConfigurationToFolder.subscribe(() => {
            this.checkSelectionStateChange();
        });

        this.subs.sink = this.aiconfigService.shareConfigurations.subscribe(() => {
            this.checkSelectionStateChange();
        });
    }

    checkSelectionStateChange() {
        if ((this.aiconfigService.addConfigurationToFolder.getValue() || this.aiconfigService.shareConfigurations.getValue()) !== this.selection) {
            this.selection = this.aiconfigService.addConfigurationToFolder.getValue() || this.aiconfigService.shareConfigurations.getValue();
        }
    }

    // eslint-disable-next-line class-methods-use-this
    toggleFolder(folder) {
        folder.state = !folder.state;
    }

    addConfigurationsToFolder(folder, fromToolbar: boolean = true) {
        this.aiconfigService.addConfigurationToFolderFromToolbar = fromToolbar;
        this.switchToAddMode.emit(folder);
    }

    getFolderSize(folder: Folder) {
        return this.folders.find((f) => f.fold_id === folder.fold_id).content?.length || 0;
    }

    async deleteFolder(folder) {
        const modal = this.modalService.open(ModalComponent, {});
        modal.componentInstance.titleToTranslate = 'translations.analysisDashboard.aiconfig.delete_folder_modal_title';
        modal.componentInstance.content = this.translateService.instant('translations.analysisDashboard.aiconfig.delete_folder_modal_text', { name: folder.fold_name });
        modal.componentInstance.btnCloseToTranslate = 'translations.utils.cancel';
        modal.componentInstance.btnValidToTranslate = 'translations.utils.delete';
        modal.componentInstance.btnValidVariant = 'primary';
        modal.componentInstance.status.subscribe(async (s: boolean) => {
            if (s) {
                gtmClick({
                    track_category: 'gestion configuration IA',
                    track_name: 'supprimer dossier configuration IA',
                    track_cible: 'supprimer',
                });

                this.aiconfigService.deleteFolder(folder);
            } else {
                gtmClick({
                    track_category: 'gestion configuration IA',
                    track_name: 'supprimer dossier configuration IA',
                    track_cible: 'annuler',
                });
            }
        });
    }

    async changeFolderName(editedFolder, newName) {
        await this.aiconfigService.renameFolder(editedFolder, newName);
        this.editedFolder = undefined;
    }
}
