import { IaConfigResponse } from 'app/api/models/iaconfig';

export default class ConfigurationRow implements IaConfigResponse {
    constructor(public configuration: IaConfigResponse) {}

    isCollapsed = true;

    get config_id() {
        return this.configuration.config_id;
    }

    get is_favori() {
        return this.configuration.is_favori;
    }

    get config_name() {
        return this.configuration.config_name;
    }

    get config_job() {
        return this.configuration.config_job;
    }

    get config_role() {
        return this.configuration.config_role;
    }

    get config_configuration() {
        return this.configuration.config_configuration;
    }

    get config_mode_poc() {
        return this.configuration.config_mode_poc;
    }

    get config_context() {
        return this.configuration.config_context;
    }

    get config_update_date() {
        return (this.configuration.config_update_date || this.config_creation_date || '-').substring(0, 10);
    }

    get config_creation_date() {
        return (this.configuration.config_creation_date || '-').substring(0, 10);
    }

    get shared_used() {
        return this.configuration.shared_used;
    }

    get config_shared() {
        return this.perso ? this.configuration.config_shared : false;
    }

    get nb_dashboards() {
        return this.configuration.nb_dashboards;
    }

    get folders() {
        return this.configuration.folders;
    }

    set folders(folders) {
        this.configuration.folders = folders;
    }

    get perso() {
        return this.configuration.perso;
    }

    get origin() {
        return this.configuration.origin;
    }

    get shared_with() {
        return this.configuration.shared_with || {
            listSelect: [],
            sharedAll: false,
            sharedSelect: false,
        };
    }

    get sharing_date() {
        return (this.configuration.sharing_date || '-').substring(0, 10);
    }

    get usernameOwner() {
        return this.configuration.usernameOwner;
    }
}
