/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable class-methods-use-this */
import {
    HttpClient, HttpHeaders, HttpRequest, HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AssociatedDataDisplay, BarChartParamsConfig, ComparisonConfig, ComparisonMode, GraphParamsConfig, KeywordExpression,
} from 'app/api/models/dashboard-config';
import CorpusService from 'app/utils/services/corpus.service';
import * as moment from 'moment';
import { Observable, Observable as __Observable } from 'rxjs';
import { filter as __filter, map as __map } from 'rxjs/operators';
import download from 'app/utils/functions/download';
import ApiConfiguration from '../api-configuration';
import BaseService from '../base-service';
import { DashboardType, Dashboardforuser, Dashboardparams } from '../models';
import { Classdatacountperiod } from '../models/classdatacountperiod';
import { CorpusDataDownload } from '../models/corpus-data-download';
import { Countfiltersdataparams } from '../models/countfiltersdataparams';
import { Countthematicsparams } from '../models/countthematicsparams';
import { CreateDashboard } from '../models/create-dashboard';
import { Dashboardlist } from '../models/dashboardlist';
import { DataCountPeriodMerged, Datacountperiod } from '../models/datacountperiod';
import { Datatone } from '../models/datatone';
import { GroupedValue, Period } from '../models/distriparams';
import { Filters } from '../models/filters';
import { GraphToSatisfaction } from '../models/graphtosatisfaction';
import { XaxisSort, Yaxis } from '../models/histoparams';
import { Listusername } from '../models/listusername';
import { ResultId } from '../models/result-id';
import { SatisfactionData, SatisfactionDataMerge } from '../models/satisfactiondata';
import { SatisfactionMergeParams, SatisfactionParams } from '../models/satisfactionparams';
import { Searchdata } from '../models/searchdata';
import { SearchFieldType, Searchparams } from '../models/searchparams';
import { Searchtoneparams } from '../models/searchtoneparams';
import { Searchverbatimparams } from '../models/searchverbatimparams';
import { Thematicsdatacount } from '../models/thematicsdatacount';
import { Thematicsdatacountperiod } from '../models/thematicsdatacountperiod';
import { TographParams } from '../models/tographparams';
import { UpdateDashboard } from '../models/update-dashboard';
import { VerbatimConfig } from '../models/verbatimconfig';
import { Verbatimtone } from '../models/verbatimtone';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { KeywordsData } from '../models/keywordsdata';
import { ResponseHeatmap } from '../models/responseheatmap';
import { RequestHeatmap } from '../models/requestheatmap';
import { HeatmapParams, Method } from '../models/graphparams';
import { Annotations } from '../models/annotations';
import { Annotationforreset } from '../models/annotationforreset';
import { CopcData } from '../models/copcdata';
import { CopcConfig } from '../models/copcconfig';
import {
    MergedDistributionData, MergedDistributionParams, MergedRequestMatrix, MergedResponseMatrix,
} from '../models/paramsformerge';
import { MergedAssociatedData, RequestAssociatedData } from '../models/associateddata';
import { Discoveries, DiscoveryConfiguration, ExplorationLLM } from '../models/llm';
import { Conversation, ConversationResponse, NbVerbatim } from '../models/conversation';
import { InterviewResponse } from '../models/interview';
import {
    IaConfigDefaultNameResponse, IaConfigParams, IaConfigResponse, IaConfigs,
} from '../models/iaconfig';

/**
 * Dashboards management
 */
@Injectable({
    providedIn: 'root',
})
class DashboardService extends BaseService {
    constructor(
        config: ApiConfiguration,
        http: HttpClient,
        private corpusService: CorpusService,
    ) {
        super(config, http);
    }

    private preprocessDashboardforuser(dashboard: Dashboardforuser): Dashboardforuser {
        // if (dashboard.dash_json_params && dashboard.dash_json_params.period) {
        if (typeof dashboard.dash_json_config === 'string') {
            try {
                dashboard.dash_json_config = JSON.parse(dashboard.dash_json_config);
            } catch (e) {
                // nothing to do here
            }
        }

        if (!dashboard.dash_json_config) {
            dashboard.dash_json_config = {};
        }

        if (!dashboard.dash_json_params) {
            dashboard.dash_json_params = {};
        }

        if (dashboard.dash_json_config.verbatim) {
            if (Object.prototype.hasOwnProperty.call(dashboard.dash_json_config.verbatim, 'freesearch')) {
                dashboard.dash_json_params.freesearch = dashboard.dash_json_config.verbatim.freesearch;
                delete dashboard.dash_json_config.verbatim.freesearch;
            }

            if (Object.prototype.hasOwnProperty.call(dashboard.dash_json_config.verbatim, 'keywordsearch')) {
                dashboard.dash_json_params.keywordsearch = dashboard.dash_json_config.verbatim.keywordsearch;
                delete dashboard.dash_json_config.verbatim.keywordsearch;
            }

            if (Object.prototype.hasOwnProperty.call(dashboard.dash_json_config.verbatim, 'pert_is_disabled')) {
                delete dashboard.dash_json_config.verbatim.pert_is_disabled;
            }
        }

        if (!dashboard.dash_json_config.copc) {
            dashboard.dash_json_config.copc = this.getDefaultCopcConfig();
        }

        if (!dashboard.dash_json_config.userChoices) {
            // dans le cas d'un dashboard initial, il n'y a pas de userchoices, donc on instancie une liste vide
            dashboard.dash_json_config.userChoices = [];
        } else if (Object.prototype.hasOwnProperty.call(dashboard.dash_json_config.userChoices, 'userChoices')) {
            // dans le cas où ils sont dans un sous niveau, on les migre au niveau supérieur
            // eslint-disable-next-line @typescript-eslint/dot-notation
            dashboard.dash_json_config.userChoices = dashboard.dash_json_config.userChoices['userChoices'];
        }

        ['thematics', 'class'].forEach((value) => {
            if (!dashboard.dash_json_config[value]) {
                dashboard.dash_json_config[value] = this.getDefaultDistributionConfig();
            }

            if (!Object.prototype.hasOwnProperty.call(dashboard.dash_json_config[value].histo, 'tone')) {
                dashboard.dash_json_config[value].histo = this.getDefaultDistributionHistoConfig();
            }

            if (!Object.prototype.hasOwnProperty.call(dashboard.dash_json_config[value], 'legend_distri')) {
                dashboard.dash_json_config[value].legend_distri = [];
            }

            if (!Object.prototype.hasOwnProperty.call(dashboard.dash_json_config[value], 'legend_histo')) {
                dashboard.dash_json_config[value].legend_histo = [];
            }

            if (!Object.prototype.hasOwnProperty.call(dashboard.dash_json_config[value], 'legend_matrix')) {
                dashboard.dash_json_config[value].legend_matrix = [];
            }
        });

        if (!dashboard.dash_json_config.evoTemp) {
            dashboard.dash_json_config.evoTemp = this.getDefaultDistributionEvoTemp();
        }

        dashboard.dash_json_config.verbatim = this.populateDefaultVerbatimConfig(dashboard.dash_json_config.verbatim);
        dashboard.dash_json_config.satisfaction = this.populateDefaultSatisfactionConfig(dashboard.dash_json_config.satisfaction);

        dashboard.dash_json_config.heatmap = this.populateDefaultHeatmapConfig(dashboard.dash_json_config.heatmap);

        if (!dashboard.dash_json_config.associated_data_display) {
            dashboard.dash_json_config.associated_data_display = AssociatedDataDisplay.both;
        }

        if (!dashboard.dash_json_config.filters) {
            dashboard.dash_json_config.filters = {
                similarity_is_checked: false,
            };
        }

        if (dashboard.dash_type === DashboardType.COMPARISON) {
            dashboard.dash_json_config.comparison = this.populateDefaultComparisonConfig(dashboard.dash_json_config.comparison);
        }

        if (!dashboard.dash_json_config.filters) {
            dashboard.dash_json_config.filters = {
                similarity_is_checked: false,
            };
        }

        // convert id to number
        dashboard.dash_id *= 1;
        dashboard.dash_json_params.period *= 1;

        // patch selected all
        if (!dashboard.dash_json_params.selected_not) {
            dashboard.dash_json_params.selected_not = false;
        }

        // calcul de la période par défaut s'il n'y a as de période
        if (!dashboard.dash_json_params.period) {
            const firstMonth = moment(dashboard.dash_json_params.date_begin, 'YYYY-MM-DD').format('YYYY-MM');
            const lastMonth = moment(dashboard.dash_json_params.date_end, 'YYYY-MM-DD').format('YYYY-MM');

            if (firstMonth === lastMonth) {
                dashboard.dash_json_params.period = 1;
            } else {
                dashboard.dash_json_params.period = 6;
            }
        }

        if (dashboard.dash_json_params.thematics) {
            dashboard.dash_json_params.thematics.sort((a, b) => a.them_name.localeCompare(b.them_name));
        }

        // supprime les filtres vides s'il y en a d'enregistrés
        dashboard.dash_json_params.filters = dashboard.dash_json_params.filters?.filter((filter) => filter.values.length !== 0 || filter.gte || filter.gt || filter.lte || filter.lt) || [];

        if (!dashboard.dash_json_config.llmProfileConfig) {
            dashboard.dash_json_config.llmProfileConfig = [];
        }

        return dashboard;
    }

    getDefaultComparisonConfig(): ComparisonConfig {
        return {
            evoTemporel: ComparisonMode.side_by_side,
            associatedData: ComparisonMode.side_by_side,
            class: ComparisonMode.side_by_side,
            satisfaction: ComparisonMode.side_by_side,
            thematics: ComparisonMode.side_by_side,
        };
    }

    getDefaultSatisfactionConfig(): GraphToSatisfaction {
        return {
            deltatone: {
                distri: {
                    period: Period.day,
                    grouped_values: GroupedValue.average,
                    legend_distri: [],
                    nb_values_chart: 50,
                    top_activated: false,
                },
                histo: {
                    xaxis_sort: XaxisSort.values_asc,
                    yaxis: Yaxis.percent,
                },
            },
            deltasat: {
                distri: {
                    period: Period.day,
                    grouped_values: GroupedValue.average,
                    legend_distri: [],
                    nb_values_chart: 50,
                    top_activated: false,
                },
                histo: {
                    xaxis_sort: XaxisSort.values_asc,
                    yaxis: Yaxis.percent,
                },
            },
            nps: {
                distri: {
                    period: Period.day,
                    grouped_values: GroupedValue.average,
                    legend_distri: [],
                    nb_values_chart: 50,
                    top_activated: false,
                },
                histo: {
                    xaxis_sort: XaxisSort.values_asc,
                    yaxis: Yaxis.percent,
                },
            },
        };
    }

    populateDefaultVerbatimConfig(config): VerbatimConfig {
        return this.populateDefaultFields(config || {}, this.getDefaultVerbatimConfig());
    }

    populateDefaultSatisfactionConfig(config): GraphToSatisfaction {
        return this.populateDefaultFields(config || {}, this.getDefaultSatisfactionConfig());
    }

    populateDefaultComparisonConfig(config): ComparisonConfig {
        return this.populateDefaultFields(config || {}, this.getDefaultComparisonConfig());
    }

    populateDefaultFields(object, defaultObject) {
        Object.keys(defaultObject).forEach((key) => {
            if (object[key] && defaultObject[key] !== null && typeof defaultObject[key] === 'object') {
                this.populateDefaultFields(object[key], defaultObject[key]);
            } else {
                this.populateDefaultField(object, key, defaultObject[key]);
            }
        });
        return object;
    }

    populateDefaultField(object, field, defaultValue) {
        object[field] = Object.prototype.hasOwnProperty.call(object, field) ? object[field] : defaultValue;
    }

    getDefaultCopcConfig(): CopcConfig {
        return {
            period: Period.month,
        };
    }

    getDefaultVerbatimConfig(): VerbatimConfig {
        const defaultSelectedColumns = [{
            displayName: this.corpusService.currentCorpus.getValue().corp_date_field.name,
            DBName: this.corpusService.currentCorpus.getValue().corp_date_field.DBName,
            type: SearchFieldType.COLUMN,
            checked: true,
        }];
        if (this.corpusService.isCorpusConversation()) {
            defaultSelectedColumns.push({
                displayName: this.corpusService.currentCorpus.getValue().corp_fields_not_in_file.find((f) => f.DBName === 'conversation_id').displayName,
                DBName: 'conversation_id',
                type: SearchFieldType.TREATMENT,
                checked: true,
            });
        }
        return {
            word_tab: 'cloud',
            cloud_max_size: 25,
            cloud_category: 'top_keywords_ec',
            cloud_sub_category: 'top_keywords_ec',
            cloud_use_delta_tone: true,
            selected_columns: defaultSelectedColumns,
            extended_cloud_max_size: 50,
            extended_cloud_type: null,
            extended_table: false,
            pert_is_checked: false,
            search_enabled: false,
        };
    }

    populateDefaultHeatmapConfig(config): HeatmapParams {
        return this.populateDefaultFields(config || {}, this.getDefaultHeatmapConfig());
    }

    getDefaultHeatmapConfig(): HeatmapParams {
        return {
            fieldx: 'class',
            fieldy: 'ton',
            axis_sort: XaxisSort.occur_desc,
            nb_values_chart: 12,
            top_activated: false,
            method: Method.khi2,
        };
    }

    getDefaultDistributionConfig(): GraphParamsConfig {
        return {
            distri: this.getDefaultDistributionDistriConfig(),
            histo: this.getDefaultDistributionHistoConfig(),
            legend_distri: [],
            legend_histo: [],
            legend_matrix: [],
            top_keyword_expression: {
                top: KeywordExpression.KEYWORD,
                max_value: 4,
            },
        };
    }

    getDefaultDistributionDistriConfig() {
        return {
            period: Period.day,
            yaxis: Yaxis.percent,
            top_activated: false,
            nb_values_chart: -1, // on met cette valeur pour indiquer que la valeur par défaut doit être calculée au moment du chargement en fonction du contexte
        };
    }

    getDefaultDistributionHistoConfig() {
        return {
            tone: this.corpusService.hasTones(),
            nb_values_chart: -1, // on met cette valeur pour indiquer que la valeur par défaut doit être calculée au moment du chargement en fonction du contexte
            top_activated: false,
            xaxis_sort: XaxisSort.occur_desc,
            yaxis: Yaxis.percent,
        };
    }

    getDefaultDistributionEvoTemp() {
        return {
            period: Period.day,
        };
    }

    getCopcData(dashId: number, period: string, dashboardParams: Dashboardparams): __Observable<CopcData> {
        return this.http.post<CopcData>(`${this.rootUrl}/v1/dashboard/${dashId}/copc/${period}`, dashboardParams);
    }

    getHeatmapFields(dashId: number, requestheatmap: RequestHeatmap): __Observable<ResponseHeatmap> {
        return this.http.post<ResponseHeatmap>(`${this.rootUrl}/v1/dashboard/${dashId}/heatmap/fields`, requestheatmap);
    }

    getHeatmap(dashId: number, requestheatmap: RequestHeatmap): __Observable<ResponseHeatmap> {
        return this.http.post<ResponseHeatmap>(`${this.rootUrl}/v1/dashboard/${dashId}/heatmap`, requestheatmap);
    }

    getMergedMatrixData(dashId: number, type: 'class' | 'thematics', requestMatrix: MergedRequestMatrix): __Observable<MergedResponseMatrix> {
        return this.http.post<MergedResponseMatrix>(`${this.rootUrl}/v1/dashboard/${dashId}/matrix/${type}`, requestMatrix);
    }

    getMergedAssociatedData(dashId: number, period, requestAssociatedData: RequestAssociatedData): __Observable<MergedAssociatedData> {
        return this.http.post<MergedAssociatedData>(`${this.rootUrl}/v1/dashboard/${dashId}/associateddata/${period}`, requestAssociatedData);
    }

    insertAnnotations(corpId: number, annotations: Annotations): __Observable<any> {
        return this.http.post(`${this.rootUrl}/v1/corpus/${corpId}/annotations`, annotations);
    }

    resetAnnotations(corpId: number, annotationForReset: Annotationforreset): __Observable<any> {
        return this.http.post(`${this.rootUrl}/v1/corpus/${corpId}/annotations/reset`, annotationForReset);
    }

    getDiscovery(dashId: number): __Observable<Discoveries> {
        return this.http.get<Discoveries>(`${this.rootUrl}/v1/dashboard/${dashId}/discovery`);
    }

    getDiscoveryConfiguration(dashId: number): __Observable<DiscoveryConfiguration[]> {
        return this.http.get<DiscoveryConfiguration[]>(`${this.rootUrl}/v1/dashboard/${dashId}/discovery/configuration`);
    }

    getDiscoveryId(dashId: number, discoveryId: number, params?: BarChartParamsConfig): __Observable<ExplorationLLM> {
        return this.http.post<ExplorationLLM>(`${this.rootUrl}/v1/dashboard/${dashId}/discovery/${discoveryId}`, params ? { graphparams: params } : null);
    }

    postDiscovery(dashId: number, configId: number): __Observable<ExplorationLLM> {
        return this.http.post<ExplorationLLM>(`${this.rootUrl}/v1/dashboard/${dashId}/discovery`, { config_id: configId });
    }

    putDiscoveryId(dashId: number, llmId: number): __Observable<ExplorationLLM> {
        return this.http.put<ExplorationLLM>(`${this.rootUrl}/v1/dashboard/${dashId}/discovery/${llmId}`, null);
    }

    patchDiscoveryId(dashId: number, llmId: number, newName: string): __Observable<ExplorationLLM> {
        return this.http.patch<ExplorationLLM>(`${this.rootUrl}/v1/dashboard/${dashId}/discovery/${llmId}`, { name: newName });
    }

    deleteDiscoveryId(dashId: number, llmId: number): void {
        this.http.delete<void>(`${this.rootUrl}/v1/dashboard/${dashId}/discovery/${llmId}`).subscribe();
    }

    // Créer ou met à jour une conversation avec un LLM
    createOrUpdateConversation(mode: 'create' | 'update', dashId: number, conversationId: string, dashboardParams: Dashboardparams[]): __Observable<NbVerbatim> {
        if (mode === 'create') {
            return this.http.post<NbVerbatim>(`${this.rootUrl}/v1/dashboard/${dashId}/conversation`, { searchparams: dashboardParams });
        }
        return this.http.patch<NbVerbatim>(`${this.rootUrl}/v1/dashboard/${dashId}/conversation/${conversationId}`, { searchparams: dashboardParams });
    }

    // Envoi les messages au LLM pour continuer une conversation avec le chatbot
    postChatbotConversation(dashId: number, conversation: Conversation): __Observable<ConversationResponse> {
        const { id, ...conv } = conversation;
        return this.http.post<ConversationResponse>(`${this.rootUrl}/v1/dashboard/${dashId}/conversation/${id}`, conv);
    }

    // Créer une nouvelle configuration pour la découverte
    createChatbotInterview(dashId: number, dashboardParams: Dashboardparams): __Observable<InterviewResponse> {
        return this.http.post<InterviewResponse>(`${this.rootUrl}/v1/dashboard/${dashId}/interview`, dashboardParams);
    }

    // Envoi les messages au LLM pour créer une configuration pour les découvertes
    postChatbotInterview(dashId: number, conversation: Conversation): __Observable<ConversationResponse> {
        const { id, ...conv } = conversation;
        return this.http.post<ConversationResponse>(`${this.rootUrl}/v1/dashboard/${dashId}/interview/${id}`, conv);
    }

    // Supprime une "interview" (configuration pour les découvertes)
    deleteChatbotInterviewId(dashId: number, interviewId: number): void {
        this.http.delete<void>(`${this.rootUrl}/v1/dashboard/${dashId}/interview/${interviewId}`).subscribe();
    }

    // Créé une nouvelle configuration à partir de la synthèse de la discussion
    postChatbotIaConfig(config: IaConfigParams): __Observable<IaConfigResponse> {
        return this.http.post<IaConfigResponse>(`${this.rootUrl}/v1/iaconfig/`, config);
    }

    // Récupère le nom de la prochaine découverte
    postChatbotIaConfigDefaultName(dashId: number): __Observable<IaConfigDefaultNameResponse> {
        return this.http.post<IaConfigDefaultNameResponse>(`${this.rootUrl}/v1/iaconfig/defaultName`, { dash_id: dashId });
    }

    // Modifie le nom d'une configuration
    patchChatbotIaConfig(iaConfigId: number, newName: string): __Observable<void> {
        return this.http.patch<void>(`${this.rootUrl}/v1/iaconfig/${iaConfigId}`, { name: newName });
    }

    deleteIaConfig(iaConfigId: number): __Observable<void> {
        return this.http.delete<void>(`${this.rootUrl}/v1/iaconfig/${iaConfigId}`);
    }

    postFavIaConfig(iaConfigId: number) {
        return this.http.post<IaConfigResponse>(`${this.rootUrl}/v1/iaconfig/${iaConfigId}/favorite`, null);
    }

    deleteFavIaConfig(iaConfigId: number) {
        return this.http.delete<void>(`${this.rootUrl}/v1/iaconfig/${iaConfigId}/favorite`);
    }

    loadIaConfigs(corpusId: number, lang: string = undefined): __Observable<IaConfigs> {
        const body = {
            corp_id: corpusId,
        };

        if (lang) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            body['lang'] = lang;
        }

        return this.http.post<IaConfigs>(`${this.rootUrl}/v1/iaconfig`, body);
    }

    /**
     * Retourne un dashboard en fonction de son ID
     */
    loadDashboard(dashId: number): __Observable<Dashboardforuser> {
        return this.http.get(`${this.rootUrl}/v1/dashboard/${dashId}`).pipe(__map((dash) => this.preprocessDashboardforuser(dash)));
    }

    addFavoriteVerbatim(dashId: number, verbatimId): __Observable<Object> {
        return this.http.post(`${this.rootUrl}/v1/dashboard/${dashId}/verbatim/${verbatimId}/favorite`, null);
    }

    removeFavoriteVerbatim(dashId: number, verbatimId): __Observable<Object> {
        return this.http.delete(`${this.rootUrl}/v1/dashboard/${dashId}/verbatim/${verbatimId}/favorite`);
    }

    /**
     * Create a dashboard for an user in a corpus
     * @param body undefined
     * @return OK
     */
    postV1DashboardResponse(body: CreateDashboard): __Observable<__StrictHttpResponse<ResultId>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;
        __body = body;
        const req = new HttpRequest<any>('POST', `${this.rootUrl}/v1/dashboard/`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<ResultId>),
        );
    }

    /**
     * Create a dashboard for an user in a corpus
     * @param body undefined
     * @return OK
     */
    postV1Dashboard(body: CreateDashboard): __Observable<ResultId> {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        return this.postV1DashboardResponse(body).pipe(__map(({ body }) => body as ResultId));
    }

    /**
     * Delete a dashboard with id dashId if the current user is owner
     * @param dashId dashboard id
     */
    deleteV1DashboardDashIdResponse(dashId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>('DELETE', `${this.rootUrl}/v1/dashboard/${dashId}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
     * Delete a dashboard with id dashId if the current user is owner
     * @param dashId dashboard id
     */
    deleteV1DashboardDashId(dashId: number): __Observable<null> {
        return this.deleteV1DashboardDashIdResponse(dashId).pipe(__map(({ body }) => body as null));
    }

    /**
     * Update the dashboard parameters. The parameters are optionals.
     * @param params The `DashboardService.PatchV1DashboardDashIdParams` containing the following parameters:
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     */
    patchV1DashboardDashIdResponse(params: DashboardService.PatchV1DashboardDashIdParams): __Observable<__StrictHttpResponse<Dashboardforuser>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>('PATCH', `${this.rootUrl}/v1/dashboard/${params.dashId}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Dashboardforuser>),
        );
    }

    getSatisafactionGraphData(dashId: number, period: string, params: SatisfactionParams): Observable<SatisfactionData> {
        return this.http.post<SatisfactionData>(`${this.rootUrl}/v1/dashboard/${dashId}/satisfaction/${period}`, params);
    }

    getSatisafactionMergedData(dashId: number, params: SatisfactionMergeParams): Observable<SatisfactionDataMerge> {
        return this.http.post<SatisfactionDataMerge>(`${this.rootUrl}/v1/dashboard/${dashId}/satisfaction`, params);
    }

    updateDashboard(params: DashboardService.PatchV1DashboardDashIdParams): __Observable<Dashboardforuser> {
        // this.migratePeriodFromConfigToParams(params.body);
        return this.http.patch(`${this.rootUrl}/v1/dashboard/${params.dashId}`, params.body).pipe(__map((dashboard) => this.preprocessDashboardforuser(dashboard) as Dashboardforuser));
    }

    getDistributionMergedData(dashId: number, type: string, params: MergedDistributionParams): __Observable<MergedDistributionData> {
        return this.http.post<MergedDistributionData>(`${this.rootUrl}/v1/dashboard/${dashId}/${type}`, params);
    }

    getDistributionMergePeriod(dashId: number, period: Period, params: { searchparams: Array<Searchparams> }): __Observable<DataCountPeriodMerged> {
        return this.http.post<DataCountPeriodMerged>(`${this.rootUrl}/v1/dashboard/${dashId}/data/distribution/${period}`, params);
    }

    /**
     * Update users sharing the dashboard
     * @param params The `DashboardService.PutV1DashboardDashIdShareParams` containing the following parameters:
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     */
    putV1DashboardDashIdShareResponse(params: DashboardService.PutV1DashboardDashIdShareParams): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>('PUT', `${this.rootUrl}/v1/dashboard/${params.dashId}/share`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
     * Update users sharing the dashboard
     * @param params The `DashboardService.PutV1DashboardDashIdShareParams` containing the following parameters:
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     */
    putV1DashboardDashIdShare(params: DashboardService.PutV1DashboardDashIdShareParams): __Observable<null> {
        return this.putV1DashboardDashIdShareResponse(params).pipe(__map(({ body }) => body as null));
    }

    private preprocessDashboardList(dashboardList: Dashboardlist): Dashboardlist {
        dashboardList.personalDashboards.forEach((dashboard) => this.preprocessDashboardforuser(dashboard));
        dashboardList.sharedDashboards.forEach((dashboard) => this.preprocessDashboardforuser(dashboard));
        if (dashboardList.otherDashboards) {
            dashboardList.otherDashboards.forEach((dashboard) => this.preprocessDashboardforuser(dashboard));
        }
        return dashboardList;
    }

    loadDashboardsByType(corpId: number, type: string): __Observable<Dashboardlist> {
        return this.http.get(`${this.rootUrl}/v1/dashboard/corpus/${corpId}/${type}`).pipe(__map((list) => this.preprocessDashboardList(list) as Dashboardlist));
    }

    /**
     * Update a flag default on a dashboard
     * @param dashId dashboard id
     */
    putV1DashboardDashIdDefaultResponse(dashId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>('PUT', `${this.rootUrl}/v1/dashboard/${dashId}/default`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
     * Update a flag default on a dashboard
     * @param dashId dashboard id
     */
    putV1DashboardDashIdDefault(dashId: number): __Observable<null> {
        return this.putV1DashboardDashIdDefaultResponse(dashId).pipe(__map(({ body }) => body as null));
    }

    /**
     * Update a flag default on a dashboard
     * @param dashId dashboard id
     */
    deleteV1DashboardDashIdDefaultResponse(dashId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>('DELETE', `${this.rootUrl}/v1/dashboard/${dashId}/default`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
     * Update a flag default on a dashboard
     * @param dashId dashboard id
     */
    deleteV1DashboardDashIdDefault(dashId: number): __Observable<null> {
        return this.deleteV1DashboardDashIdDefaultResponse(dashId).pipe(__map(({ body }) => body as null));
    }

    /**
     * Get dashboard research data by id
     * @param params The `DashboardService.PostV1DashboardDashIdDataParams` containing the following parameters:
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdDataResponse(params: DashboardService.PostV1DashboardDashIdDataParams): __Observable<__StrictHttpResponse<Searchdata>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>('POST', `${this.rootUrl}/v1/dashboard/${params.dashId}/data`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Searchdata>),
        );
    }

    /**
     * Get dashboard research data by id
     * @param params The `DashboardService.PostV1DashboardDashIdDataParams` containing the following parameters:
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdData(params: DashboardService.PostV1DashboardDashIdDataParams): __Observable<Searchdata> {
        return this.postV1DashboardDashIdDataResponse(params).pipe(__map(({ body }) => body as Searchdata));
    }

    /**
     * Get keywords data
     */
    postV1DashboardDashIdKeywords(dashId: number, filters: Searchparams): __Observable<KeywordsData> {
        return this.http.post<KeywordsData>(`${this.rootUrl}/v1/dashboard/${dashId}/keywords`, filters);
    }

    /**
     * Get the number of verbatims by all Thematic
     * @param params The `DashboardService.PostV1DashboardDashIdThematicsDataParams` containing the following parameters:
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdThematicsDataResponse(params: DashboardService.PostV1DashboardDashIdThematicsDataParams): __Observable<__StrictHttpResponse<Thematicsdatacount>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>('POST', `${this.rootUrl}/v1/dashboard/${params.dashId}/thematics/data`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Thematicsdatacount>),
        );
    }

    /**
     * Get the number of verbatims by all Thematic
     * @param params The `DashboardService.PostV1DashboardDashIdThematicsDataParams` containing the following parameters:
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdThematicsData(params: DashboardService.PostV1DashboardDashIdThematicsDataParams): __Observable<Thematicsdatacount> {
        return this.postV1DashboardDashIdThematicsDataResponse(params).pipe(__map(({ body }) => body as Thematicsdatacount));
    }

    /**
     * Get a distribution about the thematics by period (month, week, ...)
     * @param params The `DashboardService.PostV1DashboardDashIdThematicsDistributionPeriodParams` containing the following parameters:
     *
     * - `period`: period : week, month
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdThematicsDistributionPeriodResponse(
        params: DashboardService.PostV1DashboardDashIdThematicsDistributionPeriodParams,
    ): __Observable<__StrictHttpResponse<Thematicsdatacountperiod>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>('POST', `${this.rootUrl}/v1/dashboard/${params.dashId}/thematics/distribution/${params.period}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Thematicsdatacountperiod>),
        );
    }

    /**
     * Get a distribution about the thematics by period (month, week, ...)
     * @param params The `DashboardService.PostV1DashboardDashIdThematicsDistributionPeriodParams` containing the following parameters:
     *
     * - `period`: period : week, month
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdThematicsDistributionPeriod(params: DashboardService.PostV1DashboardDashIdThematicsDistributionPeriodParams): __Observable<Thematicsdatacountperiod> {
        return this.postV1DashboardDashIdThematicsDistributionPeriodResponse(params).pipe(__map(({ body }) => body as Thematicsdatacountperiod));
    }

    /**
     *  Soft Sam Sophia VG oct 2019 add new api service !!!!!!!!!!!!!!!!!!!!!!!
     * to get all result by period
     */
    /**
     * Get a distribution about the thematics by period (month, week, ...)
     * @param params The `DashboardService.PostV1DashboardDashIdDataDistributionPeriodParams` containing the following parameters:
     *
     * - `period`: period : week, month
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdDataDistributionPeriodResponse(params: DashboardService.PostV1DashboardDashIdDataDistributionPeriodParams): __Observable<__StrictHttpResponse<Datacountperiod>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>('POST', `${this.rootUrl}/v1/dashboard/${params.dashId}/data/distribution/${params.period}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Datacountperiod>),
        );
    }

    /**
     * Get a distribution about the all data by period (month, week, ...)
     * @param params The `DashboardService.PostV1DashboardDashIdDataDistributionPeriodPeriodParams` containing the following parameters:
     *
     * - `period`: period : week, month
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdDataDistributionPeriod(params: DashboardService.PostV1DashboardDashIdThematicsDistributionPeriodParams): __Observable<Datacountperiod> {
        return this.postV1DashboardDashIdDataDistributionPeriodResponse(params).pipe(__map(({ body }) => body as Datacountperiod));
    }

    /**
     * Download corpus research data by dashabord id on a CSV file
     * @param params The `DashboardService.PostV1DashboardDashIdDataDownloadParams` containing the following parameters:
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     */
    postV1DashboardDashIdDataDownloadResponse(params: DashboardService.PostV1DashboardDashIdDataDownloadParams): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>('POST', `${this.rootUrl}/v1/dashboard/${params.dashId}/data/download`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
     * Download corpus research data by dashabord id on a CSV file
     * @param params The `DashboardService.PostV1DashboardDashIdDataDownloadParams` containing the following parameters:
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     */
    postV1DashboardDashIdDataDownload(params: DashboardService.PostV1DashboardDashIdDataDownloadParams): __Observable<null> {
        return this.postV1DashboardDashIdDataDownloadResponse(params).pipe(__map(({ body }) => body as null));
    }

    /**
     * Get dashboard research data for tone by id
     * @param params The `DashboardService.PostV1DashboardDashIdToneParams` containing the following parameters:
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdToneResponse(params: DashboardService.PostV1DashboardDashIdToneParams): __Observable<__StrictHttpResponse<Datatone>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>('POST', `${this.rootUrl}/v1/dashboard/${params.dashId}/tone`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Datatone>),
        );
    }

    /**
     * Get dashboard research data for tone by id
     * @param params The `DashboardService.PostV1DashboardDashIdToneParams` containing the following parameters:
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdTone(params: DashboardService.PostV1DashboardDashIdToneParams): __Observable<Datatone> {
        return this.postV1DashboardDashIdToneResponse(params).pipe(__map(({ body }) => body as Datatone));
    }

    /**
     * Get verbatim with tonality pos or neg for class or subclass by period
     * @param params The `DashboardService.PostV1DashboardDashIdToneVerbatimParams` containing the following parameters:
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdToneVerbatimResponse(params: DashboardService.PostV1DashboardDashIdToneVerbatimParams): __Observable<__StrictHttpResponse<Verbatimtone>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>('POST', `${this.rootUrl}/v1/dashboard/${params.dashId}/tone/verbatim`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Verbatimtone>),
        );
    }

    /**
     * Get verbatim with tonality pos or neg for class or subclass by period
     * @param params The `DashboardService.PostV1DashboardDashIdToneVerbatimParams` containing the following parameters:
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdToneVerbatim(params: DashboardService.PostV1DashboardDashIdToneVerbatimParams): __Observable<Verbatimtone> {
        return this.postV1DashboardDashIdToneVerbatimResponse(params).pipe(__map(({ body }) => body as Verbatimtone));
    }

    /**
     * Download tone data by dashabord id on a CSV file
     * @param params The `DashboardService.PostV1DashboardDashIdToneDownloadParams` containing the following parameters:
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     */
    postV1DashboardDashIdToneDownloadResponse(params: DashboardService.PostV1DashboardDashIdToneDownloadParams): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>('POST', `${this.rootUrl}/v1/dashboard/${params.dashId}/tone/download`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
     * Download tone data by dashabord id on a CSV file
     * @param params The `DashboardService.PostV1DashboardDashIdToneDownloadParams` containing the following parameters:
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     */
    postV1DashboardDashIdToneDownload(params: DashboardService.PostV1DashboardDashIdToneDownloadParams): __Observable<null> {
        return this.postV1DashboardDashIdToneDownloadResponse(params).pipe(__map(({ body }) => body as null));
    }

    /**
     * Get a distribution about the class by period (month, week, ...)
     * @param params The `DashboardService.PostV1DashboardDashIdClassDistributionPeriodParams` containing the following parameters:
     *
     * - `period`: period : week, month
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdClassDistributionPeriodResponse(params: DashboardService.PostV1DashboardDashIdClassDistributionPeriodParams): __Observable<__StrictHttpResponse<Classdatacountperiod>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>('POST', `${this.rootUrl}/v1/dashboard/${params.dashId}/class/distribution/${params.period}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Classdatacountperiod>),
        );
    }

    postV1DashboardDashIdDistributionPeriodResponse(
        type: string,
        params: DashboardService.PostV1DashboardDashIdClassDistributionPeriodParams,
    ): __Observable<__StrictHttpResponse<Classdatacountperiod>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>('POST', `${this.rootUrl}/v1/dashboard/${params.dashId}/${type}/distribution/${params.period}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Classdatacountperiod>),
        );
    }

    /**
     * Get a distribution about the class by period (month, week, ...)
     * @param params The `DashboardService.PostV1DashboardDashIdClassDistributionPeriodParams` containing the following parameters:
     *
     * - `period`: period : week, month
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdClassDistributionPeriod(params: DashboardService.PostV1DashboardDashIdClassDistributionPeriodParams): __Observable<Classdatacountperiod> {
        return this.postV1DashboardDashIdClassDistributionPeriodResponse(params).pipe(__map(({ body }) => body as Classdatacountperiod));
    }

    postV1DashboardDashIdDistributionPeriod(typ: string, params: DashboardService.PostV1DashboardDashIdClassDistributionPeriodParams): __Observable<Classdatacountperiod> {
        return this.postV1DashboardDashIdDistributionPeriodResponse(typ, params).pipe(__map(({ body }) => body as Classdatacountperiod));
    }

    /**
     *  VG ADD JULLY 2019 SOFT SAM
     */

    /**
     *
     * New way to call API to draw data Bar into analysis dashboard
     */

    /**
     * Get the number of verbatim for all values of each filter (with a context defined)
     * @param params The `DashboardService.PostV1DashboardDashIdFiltersData` containing the following parameters:
     *
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdFiltersDataResponse(params: DashboardService.PostV1DashboardDashIdFiltersDataParams): __Observable<__StrictHttpResponse<Classdatacountperiod>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>('POST', `${this.rootUrl}/v1/dashboard/${params.dashId}/filters/data`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Classdatacountperiod>),
        );
    }

    /**
     * Get a distribution about the class by period (month, week, ...)
     * @param params The `DashboardService.PostV1DashboardDashIdClassDistributionPeriodParams` containing the following parameters:
     *
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdFiltersData(params: DashboardService.PostV1DashboardDashIdFiltersDataParams): __Observable<Classdatacountperiod> {
        return this.postV1DashboardDashIdFiltersDataResponse(params).pipe(__map(({ body }) => body as Classdatacountperiod));
    }

    /**
     * Get a distribution about the thematics by period (month, week, ...)
     * @param params The `DashboardService.PostV1DashboardDashIdThematicsDistributionPeriodParams` containing the following parameters:
     *
     * - `period`: period : week, month
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdFiltersDistributionPeriodResponse(params: DashboardService.PostV1DashboardDashIdFiltersDistributionPeriodParams): __Observable<__StrictHttpResponse<Filters>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>('POST', `${this.rootUrl}/v1/dashboard/${params.dashId}/filters/distribution/${params.period}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Filters>),
        );
    }

    /**
     * Get a distribution about the thematics by period (month, week, ...)
     * @param params The `DashboardService.PostV1DashboardDashIdThematicsDistributionPeriodParams` containing the following parameters:
     *
     * - `period`: period : week, month
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdFiltersDistributionPeriod(params: DashboardService.PostV1DashboardDashIdFiltersDistributionPeriodParams): __Observable<Filters> {
        return this.postV1DashboardDashIdFiltersDistributionPeriodResponse(params).pipe(__map(({ body }) => body as Filters));
    }

    /**
     * NEW API VERB to retrieve data to draw graph and temporal curves by period (month, week, ...) it allows us to draw both graph bar and temporal curve at the same time
     * @param params The `DashboardService.postV1DashboardDashIdFiltersTographPeriodResponse` containing the following parameters:
     *
     * - `period`: period : week, month
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdFiltersTographPeriodResponse(params: DashboardService.postV1DashboardDashIdFiltersTographPeriodParams): __Observable<__StrictHttpResponse<Filters>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>('POST', `${this.rootUrl}/v1/dashboard/${params.dashId}/filters/tograph/${params.period}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json',
        });

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Filters>),
        );
    }

    /**
     * NEW API VERB to Get data of a distribution about the thematics by period (month, week, ...) in order to draw both, graph bars and temporal curves at the same time
     * @param params The `DashboardService.postV1DashboardDashIdFiltersTographPeriod` containing the following parameters:
     *
     * - `period`: period : week, month
     *
     * - `dashId`: dashboard id
     *
     * - `body`:
     *
     * @return OK
     */
    postV1DashboardDashIdFiltersTographPeriod(params: DashboardService.postV1DashboardDashIdFiltersTographPeriodParams): __Observable<Filters> {
        return this.postV1DashboardDashIdFiltersTographPeriodResponse(params).pipe(__map(({ body }) => body as Filters));
    }

    downloadKeywords(dashId: number, cleanKeywordType: string, filters: Searchparams) {
        this.http.post(`${this.rootUrl}/v1/dashboard/${dashId}/keyword/${cleanKeywordType}/download`, filters, { responseType: 'blob', observe: 'response' }).subscribe((resp) => {
            const filename = resp.headers.get('content-disposition').split('=')[1];
            download(window.URL.createObjectURL(resp.body), filename);
        });
    }

    downloadMergedDistributionTops(dashId: number, distributionType: 'class' | 'thematics', downloadParams: MergedRequestMatrix) {
        this.http.post(`${this.rootUrl}/v1/dashboard/${dashId}/${distributionType}/keywords/download`, downloadParams, { responseType: 'blob', observe: 'response' }).subscribe((resp) => {
            const filename = resp.headers.get('content-disposition').split('=')[1];
            download(window.URL.createObjectURL(resp.body), filename);
        });
    }
} // END OF  class DashboardService extends BaseService

module DashboardService {
    /**
     * Parameters for patchV1DashboardDashId
     */
    export interface PatchV1DashboardDashIdParams {
        /**
         * dashboard id
         */
        dashId: number;
        body: UpdateDashboard;
    }

    /**
     * Parameters for putV1DashboardDashIdShare
     */
    export interface PutV1DashboardDashIdShareParams {
        /**
         * dashboard id
         */
        dashId: number;
        body: Listusername;
    }

    /**
     * Parameters for postV1DashboardDashIdData
     */
    export interface PostV1DashboardDashIdDataParams {
        /**
         * dashboard id
         */
        dashId: number;
        body?: Searchparams;
        page?: number;
    }

    /**
     * Parameters for postV1DashboardDashIdThematicsData
     */
    export interface PostV1DashboardDashIdThematicsDataParams {
        /**
         * dashboard id
         */
        dashId: number;
        body?: Countthematicsparams;
    }

    /**
     * Parameters for postV1DashboardDashIdThematicsDistributionPeriod
     */
    export interface PostV1DashboardDashIdThematicsDistributionPeriodParams {
        /**
         * period : week, month
         */
        period: string;

        /**
         * dashboard id
         */
        dashId: number;
        body?: Countthematicsparams;
    }

    /**
  * Soft Sam Sophia VG add oct 2019
  * /

  /**
   *
   * Parameters for postV1DashboardDashIdThematicsDistributionPeriod
   */
    export interface PostV1DashboardDashIdDataDistributionPeriodParams {
        /**
         * period : week, month
         */
        period: string;

        /**
         * dashboard id
         */
        dashId: number;
        body?: Countthematicsparams; // to be checked ??
    }

    /**
     * Parameters for postV1DashboardDashIdDataDownload
     */
    export interface PostV1DashboardDashIdDataDownloadParams {
        /**
         * dashboard id
         */
        dashId: number;
        body?: CorpusDataDownload;
    }

    /**
     * Parameters for postV1DashboardDashIdTone
     */
    export interface PostV1DashboardDashIdToneParams {
        /**
         * dashboard id
         */
        dashId: number;
        body?: Searchtoneparams;
    }

    /**
     * Parameters for postV1DashboardDashIdToneVerbatim
     */
    export interface PostV1DashboardDashIdToneVerbatimParams {
        /**
         * dashboard id
         */
        dashId: number;
        body?: Searchverbatimparams;
    }

    /**
     * Parameters for postV1DashboardDashIdToneDownload
     */
    export interface PostV1DashboardDashIdToneDownloadParams {
        /**
         * dashboard id
         */
        dashId: number;
        body?: Searchverbatimparams;
    }

    /**
     * Parameters for postV1DashboardDashIdClassDistributionPeriod
     */
    export interface PostV1DashboardDashIdClassDistributionPeriodParams {
        /**
         * period : week, month
         */
        period: string;

        /**
         * dashboard id
         */
        dashId: number;
        body?: Countthematicsparams;
    }

    /**
     * SOFT SAM ADD JULLY 2019 SOFT SAM SOPHIA
     */
    /**
     * Parameters for postV1DashboardDashIdFiltersData
     */
    export interface PostV1DashboardDashIdFiltersDataParams {
        /**
         * dashboard id
         */
        dashId: number;
        body?: Countfiltersdataparams;
    }

    /**
     * SOFT SAM  ADD
     */

    /**
     * Parameters for postV1DashboardDashIdFiltersDistributionPeriod
     */
    export interface PostV1DashboardDashIdFiltersDistributionPeriodParams {
        /**
         * period : week, month
         */
        period: string;

        /**
         * dashboard id
         */
        dashId: number;
        body?: Countfiltersdataparams;
    }

    /** SOFT SAM ADD MARCH 2020 */
    /**
     * Parameters for postV1DashboardDashIdFiltersTographPeriod
     */
    export interface postV1DashboardDashIdFiltersTographPeriodParams {
        /**
         * period : week, month
         */
        period: string;

        /**
         * dashboard id
         */
        dashId: number;
        body?: TographParams;
    }
}

export default DashboardService;
