import {
    Component, ElementRef, OnInit, ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    ChatMode, Conversation, NbVerbatim, Role,
} from 'app/api/models/conversation';
import { DashboardService as DashboardApi } from 'app/api/services';
import { BaseComponent } from 'app/base.component';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { firstValueFrom } from 'rxjs';
import MarkdownIt from 'markdown-it';
import * as _ from 'lodash';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import ChatService from 'app/modules/corpus/corpus-chat.service';
import CorpusService from 'app/utils/services/corpus.service';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_CONTEXT, DEFAULT_MODEL_ID, DEFAULT_TEMPERATURE } from '../chat-dinootoo.component';

@Component({
    selector: 'app-chat-dinootoo-conversation',
    templateUrl: './chat-dinootoo-conversation.component.html',
    styleUrls: ['./chat-dinootoo-conversation.component.scss'],
})
export default class ChatDinootooConversationComponent extends BaseComponent implements OnInit {
    public modal: NgbModalRef;

    public showToaster: boolean = false;

    public nbVerbatim: number = 0;

    public nbVerbatimComparison: number[] = [];

    public firstNbVerbatimComparison: number[] = [];

    public firstNbTokens: number;

    public nbTokens: number;

    public firstNbConversations: number;

    public nbConversations: number;

    public removeMessageIndex: number;

    public currentDashboardId: number;

    public firstNbVerbatim: number;

    public firstTotalVerbatim: number[];

    public md: MarkdownIt;

    public currentConversation: Conversation;

    public maxTokens: number;

    public isDashCompAlreadySaved: boolean;

    @ViewChild('chatContainerBody')
        chatContainerBody: ElementRef;

    // Retourne les X derniers messages de type 'user' ou 'assitant' qui ne contiennent pas d'erreur
    get contextMessage() {
        return this.chatService.conversation.getValue().messages.filter((m) => !m.error && [Role.USER, Role.ASSISTANT].includes(m.role)).slice(-20);
    }

    get isConversationMode() {
        return this.chatService.chatMode.getValue() === ChatMode.CONVERSATION;
    }

    get totalHits() {
        if (this.manageDashboardService.isDashboardComparison) {
            return this.manageDashboardService.dashboardServices.map((s) => s.dashboardData.getValue()?.hits.total);
        }
        return [this.manageDashboardService.firstDashboardService?.dashboardData.getValue()?.hits.total];
    }

    get isRandomVerbatim() {
        if (this.manageDashboardService.isDashboardComparison) {
            return this.firstNbVerbatimComparison.filter((n, i) => n !== this.totalHits[i]).length > 0;
        }
        return this.firstNbVerbatim !== this.firstTotalVerbatim[0];
    }

    constructor(
        private store: Store,
        public modalService: NgbModal,
        public chatService: ChatService,
        private dashboardApi: DashboardApi,
        public manageDashboardService: ManageDashboardService,
        public corpusService: CorpusService,
        public translate: TranslateService,
    ) {
        super();

        // Init MarkdownIt
        this.md = new MarkdownIt({
            html: false, // prevent html in markdown to prevent XSS
        });
        this.md.disable('image');
    }

    ngOnInit(): void {
        this.maxTokens = this.store.snapshot().settings.vcrm.llm_limits['dinootoo-nb-max-token'];

        // Lorsque la conversation change, on met à jour la conversation courante
        this.subs.sink = this.chatService.conversation.subscribe((conversation) => {
            this.currentConversation = JSON.parse(JSON.stringify(conversation));
        });

        // Lorsque l'on ouvre le chat
        this.subs.sink = this.chatService.openChatDinootoo.subscribe(() => {
            // Si l'ID du dashboard sur lequel on a lancé la dernière conversation et différent de l'ID du dashboard courante, on lance une nouvelle conversation
            if (this.manageDashboardService.currentDashboard.getValue().dash_id && this.currentDashboardId !== this.manageDashboardService.currentDashboard.getValue().dash_id) {
                this.startNewConversation();
            }
        });

        // Lorsque l'on clique sur le bouton "Nouvelle conversation" (du header)
        this.subs.sink = this.chatService.startNewChatDinootoo.subscribe(() => {
            if (this.isConversationMode) {
                this.startNewConversation();
                this.chatService.isLoadingAnswer = false;
            }
        });

        // Lorsque l'on envoie un message
        this.subs.sink = this.chatService.sendQuestionChatDinootoo.subscribe((question) => {
            if (question && this.isConversationMode) {
                this.onSendQuestion(question);
            }
        });

        // Lorsqu'un dashboard est chargé
        this.subs.sink = this.manageDashboardService.dashboardLoaded.subscribe(async () => {
            this.isDashCompAlreadySaved = (this.manageDashboardService.isDashboardComparison) ? !!this.manageDashboardService.currentDashboard.getValue().dash_id : true;

            // si le chat est déjà ouvert et que l'on charge un dashboard, alors il faut démarrer une nouvelle conversation
            if (this.chatService.isChatDisplay && this.isDashCompAlreadySaved) {
                await this.startNewConversation();
            }
        });

        // lorsque l'on ajoute ou supprime un dashboard à la comparaison
        this.subs.sink = this.manageDashboardService.dashboardListChanged.subscribe(async () => {
            await this.updateVerbatim();
        });

        // Lorsque les filtres sont mis à jour
        this.subs.sink = this.manageDashboardService.applyNewFiltersOnAnalyseDashboard.subscribe(async () => {
            await this.updateVerbatim();
        });
    }

    // Récupère le nombre de verbatim suite à un changement de filtre ou ajout/suppression de dashboard de comparison
    private async updateVerbatim() {
        if (this.currentConversation && (!this.manageDashboardService.isDashboardComparison || (this.manageDashboardService.isDashboardComparison && this.isDashCompAlreadySaved))) {
            // Met à jour le nombre de verbatim
            const result = await this.getNbVerbatim('update', this.currentConversation.id);

            this.nbVerbatim = result.nb_verbatim;
            this.nbTokens = result.nb_tokens;
            this.nbConversations = result.nb_conversations;
            this.nbVerbatimComparison = result.metrics_comparison.map((m) => m.nb_verbatim);

            // Si aucun message n'a été échangé, on met à juste à jour le nombre de verbatim dans la 2ème bulle d'intro
            if (this.currentConversation.messages.length === 0) {
                this.firstNbVerbatim = this.nbVerbatim;
                this.firstNbConversations = this.nbConversations;
                this.firstNbTokens = this.nbTokens;
                this.firstNbVerbatimComparison = this.nbVerbatimComparison;
                this.firstTotalVerbatim = this.totalHits;
            } else {
                // Sinon on affiche un message d'avertissement de changement de filtre
                // Si le dernier message était déjà un message de modification de filtres, on supprime l'ancien
                if (this.currentConversation.messages[this.currentConversation.messages.length - 1].role === Role.NEW_FILTER) {
                    this.currentConversation.messages.splice(-1);
                }
                if (this.manageDashboardService.isDashboardComparison) {
                    const isNbVerbatimRandom = this.nbVerbatimComparison.filter((n, i) => n !== this.totalHits[i]).length > 0;
                    this.currentConversation.messages.push({
                        role: Role.NEW_FILTER,
                        content: `translations.analysisDashboard.chatDinootoo.chatbot.conversation.newFilter.content.${
                            isNbVerbatimRandom ? 'random' : 'fixe'}_comparison_${this.manageDashboardService.dashboardServices.length}_dashs`,
                        detail: {
                            nbVerbatim: this.nbVerbatimComparison,
                            isNbVerbatimRandom,
                        },
                    });
                } else {
                    const isNbVerbatimRandom = this.nbVerbatim !== this.totalHits[0];
                    this.currentConversation.messages.push({
                        role: Role.NEW_FILTER,
                        content: `translations.analysisDashboard.chatDinootoo.chatbot.conversation.newFilter.content.${isNbVerbatimRandom ? 'random' : 'fixe'}`,
                        detail: {
                            nbVerbatim: this.nbVerbatim,
                            isNbVerbatimRandom,
                        },
                    });
                }
                this.chatService.conversation.next(JSON.parse(JSON.stringify(this.currentConversation)));
                this.chatService.scrollToBottom.emit();
            }
        }
    }

    /**
     * Create or update conversation
     */
    private getNbVerbatim(mode: 'create' | 'update', conversationId?: string): Promise<NbVerbatim> {
        const dashboardParams = this.manageDashboardService.isDashboardComparison
            ? this.manageDashboardService.dashboardServices.map((service) => _.cloneDeep(service.lastAppliedFilters.getValue()))
            : [this.manageDashboardService.firstDashboardService.lastAppliedFilters.getValue()];
        return firstValueFrom(this.dashboardApi.createOrUpdateConversation(
            mode,
            this.manageDashboardService.currentDashboard.getValue().dash_id,
            conversationId,
            dashboardParams,
        ));
    }

    /**
     * Efface les anciens messages et démarre une nouvelle conversation
     */
    public async startNewConversation() {
        // Enregistre l'ID du dashboard sur lequel on lance la conversation
        this.currentDashboardId = this.manageDashboardService.currentDashboard.getValue().dash_id;
        // Démarre une nouvelle conversation
        const conversation = {
            modelId: DEFAULT_MODEL_ID,
            temperature: DEFAULT_TEMPERATURE,
            context: DEFAULT_CONTEXT,
            messages: [],
            id: null,
        };
        const result = await this.getNbVerbatim('create');
        conversation.id = result.id;
        this.chatService.conversation.next(conversation);
        // Récupère l'ID de la conversation et le nombre de verbatim
        this.nbVerbatim = result.nb_verbatim;
        this.nbTokens = result.nb_tokens;
        this.nbConversations = result.nb_conversations;
        this.nbVerbatimComparison = result.metrics_comparison.map((m) => m.nb_verbatim);
        this.firstNbVerbatim = this.nbVerbatim;
        this.firstNbVerbatimComparison = this.nbVerbatimComparison;
        this.firstNbTokens = this.nbTokens;
        this.firstNbConversations = this.nbConversations;
        this.firstTotalVerbatim = this.totalHits;
    }

    /**
     * Evènement lorsque l'utilisateur pose une question
     * Envoi la requête à l'API
     */
    public async onSendQuestion(question: string) {
        if (this.manageDashboardService.isDashboardComparison) {
            gtmClick({ track_category: 'dashboard comparaison', track_name: 'dinootoo db comparaison envoyer le message' });
        } else {
            gtmClick({ track_category: 'dinootoo', track_name: 'envoyer le message' });
        }
        const conversation = JSON.parse(JSON.stringify(this.chatService.conversation.getValue()));

        conversation.messages.push({
            role: Role.USER,
            content: question,
        });

        this.chatService.conversation.next(conversation);
        this.chatService.scrollToBottom.emit();

        const conversationToSend = JSON.parse(JSON.stringify(conversation));
        conversationToSend.messages = this.contextMessage;
        conversation.messages.push(await this.chatService.sendMessages(this.manageDashboardService.currentDashboard.getValue().dash_id, conversationToSend));

        this.chatService.conversation.next(conversation);
        this.chatService.scrollToBottom.emit();
    }

    /**
     * Evènement sur les boutons "delete" des messages
     * Supprime le couple question / réponse.
     */
    public onRemoveMessage() {
        // Supprime la question
        this.currentConversation.messages.splice(this.removeMessageIndex, 1);

        // Supprime la réponse (si elle existe)
        const nextMessage = this.currentConversation.messages[this.removeMessageIndex];
        if (nextMessage && nextMessage.role === Role.ASSISTANT) {
            this.currentConversation.messages.splice(this.removeMessageIndex, 1);
        }
        this.chatService.conversation.next(this.currentConversation);
        this.removeMessageIndex = undefined;
        this.modal.close();
    }

    /**
     * Evènement lorsque l'on clique sur les boutons "copy"
     * Copie dans le presse papier et affiche un message
     */
    public onCopyToClipboard(message: string) {
        if (this.manageDashboardService.isDashboardComparison) {
            gtmClick({ track_category: 'dashboard comparaison', track_name: 'dinootoo db comparaison copier dans le presse papier' });
        } else {
            gtmClick({ track_category: 'dinootoo', track_name: 'copier dans le presse papier' });
        }
        this.showToaster = true;
        navigator.clipboard.writeText(message).finally(() => {
            setTimeout(() => {
                this.showToaster = false;
            }, 3000);
        });
    }
}
