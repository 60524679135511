import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Folder } from 'app/api/models/folder';
import { IaConfigResponse, IaConfigs } from 'app/api/models/iaconfig';
import { CorpusService as CorpusApi, DashboardService } from 'app/api/services';
import { BaseComponent } from 'app/base.component';
import CorpusService from 'app/utils/services/corpus.service';
import {
    BehaviorSubject,
    firstValueFrom,
    Observable,
    Subject,
} from 'rxjs';

/**
 * Service de gestion des configurations IAs et des dossiers
 */
@Injectable()
export default class AiConfigService extends BaseComponent {
    // la liste des dossiers
    public folders: BehaviorSubject<Array<Folder>> = new BehaviorSubject<Array<Folder>>(null);

    // la liste des configurations
    public configurations: BehaviorSubject<IaConfigs> = new BehaviorSubject<IaConfigs>(null);

    // la liste des configurations sélectionées (en mode sélection). Si on est dans le mode où l'on n'a qu'une seule configuration sélectionnable, cette liste ne contient jamais plus d'un élément
    public selectedConfigurations: BehaviorSubject<Array<IaConfigResponse>> = new BehaviorSubject<Array<IaConfigResponse>>([]);

    // la liste des configuration sélectionné initiale. Vide pour le partage, avec les configuration du dossier pour le mode ajout. Cette liste permet de comparer avec la liste des configurations
    // sélectionnée pour détecter un changement
    public originalSelectedConfigurations: BehaviorSubject<Array<IaConfigResponse>> = new BehaviorSubject<Array<IaConfigResponse>>(null);

    // la listes des états des 3 catégories
    public collapsed: BehaviorSubject<boolean[]> = new BehaviorSubject<boolean[]>([false, false, false]);

    // le dossier sélectionnée
    public selectedFolder: BehaviorSubject<Folder> = new BehaviorSubject<Folder>(null);

    // indique si l'utilisateur est en train de sélectioner des configurations à ajouter ou à retirer d'un dossier
    public addConfigurationToFolder: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // indique si l'utilisateur est en train de sélectionner des configurations à partager
    public shareConfigurations: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public addConfigurationToFolderFromToolbar: boolean = false;

    // indique si l'utilisateur peut sélectionner plusieurs configuration (cas de la gestion) ou un seule (cas de la sélection d'une configuration à utiliser pour lancer une découverte)
    public selectOnlyOne: boolean = false;

    public closeAllConfigurationDetails: Subject<void> = new Subject<void>();

    constructor(private corpusApi: CorpusApi, private dashboardService: DashboardService, private corpusService: CorpusService, private translateService: TranslateService) {
        super();
    }

    async load() {
        await this.loadConfigurations();
        await this.loadFolders();
    }

    // chargement des dossiers
    async loadFolders() {
        const data = await firstValueFrom(this.corpusApi.getFolderContent(this.corpusService.currentCorpus.getValue().corp_id, 'iaconfig'));
        data.forEach((folder) => { folder.content = []; });

        // on va également utiliser les données de configurations pour lier les dossiers et les configurations entre eux
        const allConfigurations = [
            ...this.configurations.getValue().personalConfigurations,
            ...this.configurations.getValue().sharedConfigurations,
            ...this.configurations.getValue().sharedInCorpusConfigurations,
        ];
        allConfigurations.forEach((configuration) => {
            const configurationFolders = [];

            configuration.folders.forEach((f) => {
                const existingFolder = data.find((folder) => folder.fold_id === f.fold_id);
                if (existingFolder) {
                    configurationFolders.push(existingFolder);
                    existingFolder.content.push(configuration);
                }
            });

            configuration.folders = configurationFolders;
        });

        this.folders.next(data);
    }

    // chargement des configurations
    async loadConfigurations() {
        const response = await firstValueFrom(this.dashboardService.loadIaConfigs(this.corpusService.currentCorpus.getValue().corp_id));

        [response.personalConfigurations, response.sharedInCorpusConfigurations, response.sharedConfigurations].forEach((configList, index) => {
            configList.forEach((row: any) => {
                // on enrichie les configuration avec des données supplémentaires
                row.isCollapsed = true;
                row.checked = false;
                row.config_update_date = row.config_update_date ? row.config_update_date : row.config_creation_date;
                row.search = [row.config_name, row.config_configuration].join(' | ');

                row.perso = index === 0;
            });
        });

        this.configurations.next(response);
    }

    // création d'un dossier
    async createFolder(folderName) {
        const folder: Folder = await firstValueFrom(this.corpusApi.createFolder('iaconfig', folderName));
        folder.content = [];

        this.folders.getValue().push(folder);

        this.folders.next(this.folders.getValue());
    }

    // ajout de configurations à un dossier
    async addSelectedConfigurationsToFolder() {
        await firstValueFrom(this.corpusApi.updateFolderContent(this.selectedFolder.getValue().fold_id, this.selectedConfigurations.getValue().map((configuration) => configuration.config_id)));

        // on met à jour la liste des configuration du folder
        const allConfigurations = [...this.configurations.getValue().personalConfigurations,
            ...this.configurations.getValue().sharedConfigurations,
            ...this.configurations.getValue().sharedInCorpusConfigurations];

        this.selectedFolder.getValue().content = this.selectedConfigurations.getValue()
            .map((c) => allConfigurations.find((configuration) => configuration.config_id === c.config_id));

        this.selectedFolder.getValue()['list_discovery_config'] = this.selectedConfigurations.getValue()
            .map((c) => allConfigurations.find((configuration) => configuration.config_id === c.config_id));
    
        // on met  jour les folder sur les configurations
        allConfigurations.forEach((configuration) => {
            configuration.folders = this.folders.getValue().filter((folder) => folder.content.find((c) => configuration.config_id === c.config_id));
        });

        this.folders.getValue().forEach((f) => {
            f.content.forEach((configuration) => {
                configuration.folders = this.folders.getValue().filter((folder) => folder.content.find((c) => configuration.config_id === c.config_id));
            });
        });

        this.folders.next(this.folders.getValue());
    }

    // suppiression d'une configurations
    async deleteConfiguration(configuration: IaConfigResponse) {
        await firstValueFrom(this.corpusApi.deleteAiConfiguration(configuration.config_id));

        this.configurations.getValue().personalConfigurations = this.configurations.getValue().personalConfigurations.filter((c) => c.config_id !== configuration.config_id);

        this.folders.getValue().forEach((folder) => {
            folder.content = folder.content.filter((c) => c.config_id !== configuration.config_id);
        });

        this.configurations.next(this.configurations.getValue());
        this.folders.next(this.folders.getValue());
    }

    // suppression d'un dossier
    async deleteFolder(folder: Folder) {
        const configurationsOfFolder = [...folder.content];
        await firstValueFrom(this.corpusApi.deleteFolder(folder.fold_id));
        configurationsOfFolder.forEach((configuration) => { configuration.folders = configuration.folders.filter((f) => f.fold_id !== folder.fold_id); });
        this.folders.next(this.folders.getValue().filter((f) => f.fold_id !== folder.fold_id));
    }

    // changement du nom d'un dossier
    async renameFolder(folder: Folder, newName: string) {
        await firstValueFrom(this.corpusApi.renameFolder(folder.fold_id, newName));
        const renamedFolder = this.folders.getValue().find((f) => f.fold_id === folder.fold_id);
        renamedFolder.fold_name = newName;
    }

    // suppression d'une configuration d'un dossier. La configuration n'est pas détruite, elle existe toujours
    async removeConfigurationFromFolder(folder: Folder, configuration: IaConfigResponse) {
        const updatedConfigurations = folder.content.filter((c) => c.config_id !== configuration.config_id);

        await firstValueFrom(this.corpusApi.updateFolderContent(folder.fold_id, updatedConfigurations.map((c) => c.config_id)));

        folder.content = folder.content.filter((c) => c.config_id !== configuration.config_id);
        configuration.folders = configuration.folders.filter((f) => f.fold_id !== folder.fold_id);

        this.folders.next(this.folders.getValue());
    }

    // changement de nom d'une configuration
    async renameConfiguration(configuration: IaConfigResponse, newName: string): Promise<IaConfigResponse> {
        const response = await firstValueFrom(this.corpusApi.renameAiConfiguration(configuration.config_id, newName));
        configuration.config_name = newName;
        configuration.config_update_date = response.config_update_date;
        return response;
    }

    // création d'une nouvelle configuration
    async createNewConfiguration(name: string, configuration: string) {
        const newConfiguration:any = await firstValueFrom(this.corpusApi.createAiConfiguration(name, configuration));
        this.insertNewConfiguration(newConfiguration);
    }

    private insertNewConfiguration(configuration) {
        configuration.isCollapsed = true;
        configuration.checked = false;
        configuration.config_update_date = configuration.config_update_date ? configuration.config_update_date : configuration.config_creation_date;
        configuration.search = [configuration.config_name, configuration.config_configuration].join(' | ');
        configuration.perso = true;
        configuration.folders = [];

        this.configurations.getValue().personalConfigurations = [configuration, ...this.configurations.getValue().personalConfigurations];
        this.configurations.next(this.configurations.getValue());
    }

    get corpusId() {
        return this.corpusService.currentCorpus.getValue().corp_id;
    }

    // partage d'une configuration
    async shareSelectedConfigurations(shareToAll: boolean, shareInsideProject: boolean, shareOutsideProject:boolean, usersInsideProject, usersOutsideProject) {
        const shareWithUsers: boolean = shareInsideProject || shareOutsideProject;
        const users = [...usersInsideProject, ...usersOutsideProject];

        this.selectedConfigurations.getValue().forEach(async (configuration) => {
            const response = await firstValueFrom(this.corpusApi.shareAiConfiguration(this.corpusId, configuration.config_id, shareToAll, shareWithUsers, users.map((user) => user.username)));
            configuration.shared_with = response.shared_with;
            configuration.config_shared = response.config_shared;
            configuration.config_update_date = response.config_update_date;
        });
    }

    // suppression du partage à tous les utilisateurs d'une configuration
    async unshareAll(configuration: IaConfigResponse) {
        const response = await firstValueFrom(this.corpusApi.unshareAiConfiguration(this.corpusId, configuration.config_id, 'all'));
        configuration.shared_with = response.shared_with;
        configuration.config_shared = response.config_shared;
        configuration.config_update_date = response.config_update_date;
    }

    // suppresion du partage à une utilisateur spécifique d'une configuration
    async unshareUser(configuration: IaConfigResponse, userId: number) {
        const response = await firstValueFrom(this.corpusApi.unshareAiConfiguration(this.corpusId, configuration.config_id, 'select', userId));
        configuration.shared_with = response.shared_with;
        configuration.config_shared = response.config_shared;
        configuration.config_update_date = response.config_update_date;
    }

    // copie d'une configuration pratagée. Création d'une configuration personnelle.
    async copy(configuration: IaConfigResponse) : Promise<IaConfigResponse> {
        const copyBaseName = this.translateService.instant('translations.analysisDashboard.aiconfig.copy_name_prefix') + configuration.config_name;
        let newName = copyBaseName;
        let index = 1;

        const allConfigurationNames = [...this.configurations.getValue().personalConfigurations,
            ...this.configurations.getValue().sharedConfigurations,
            ...this.configurations.getValue().sharedInCorpusConfigurations].map((c) => c.config_name);

        while (allConfigurationNames.indexOf(newName) >= 0) {
            // eslint-disable-next-line no-plusplus
            newName = `${copyBaseName} (${index++})`;
        }

        const copy: any = await firstValueFrom(this.corpusApi.createAiConfiguration(newName, configuration.config_configuration));

        this.insertNewConfiguration(copy);

        return copy;
    }

    async copyIntoFolder(configuration: IaConfigResponse, folder: Folder) {
        const copy = await this.copy(configuration);

        const newFolderContent = [...folder.content.map((c) => c.config_id), copy.config_id];
        await firstValueFrom(this.corpusApi.updateFolderContent(folder.fold_id, newFolderContent));

        folder.content.push(copy);
        copy.folders = [folder];

        this.folders.next(this.folders.getValue());
    }
}
